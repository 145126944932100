:root {
    --primary-color: rgba(93, 0, 93, 1);
    --secondary-color: rgba(0, 0, 0, .88);
    --logo-font-family: 'Turret Road', cursive;
    --primary-font-family: 'Titillium Web', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-family: var(--primary-font-family);
    scroll-behavior: smooth;
}

body {
    background-color: var(--primary-color);
}



@media (min-width: 1281px) {
    * {
        font-size: 16px;
    }
}

@media (max-width: 1280px) {
    * {
        font-size: 14px;
    }
}

@media (max-width: 1024px) {
    * {
        font-size: 13px;
    }
}

@media (max-width: 768px) {
    * {
        font-size: 12px;
    }
}

@media (max-width: 640px) {
    * {
        font-size: 11px;
    }
}

@media (max-width: 460px) {
    * {
        font-size: 10px;
    }
}

@media (max-width: 320px) {
    * {
        font-size: 8px;
    }
}